@charset "UTF-8";
/* ---------------------------

	GOOGLE FONTS

--------------------------- */
@import url(https://fonts.googleapis.com/css?family=Arvo:400,700,400italic,700italic);
/* ---------------------------

	GLOBALS

--------------------------- */
/* ---------------------------

	MAINS

--------------------------- */
body {
  font-family: "Helvatica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #25292c;
  font-weight: 400; }

/* ---------------------------

	TYPOGRAPHY

--------------------------- */
a {
  color: #ff436b; }
  a:hover {
    text-decoration: none; }

p {
  color: #25292c;
  font-size: 15px; }

h1 {
  font-family: "Arvo", Georgia, Times, serif;
  color: #320912;
  font-weight: 400;
  font-size: 27px; }

h2 {
  font-family: "Arvo", Georgia, Times, serif;
  font-size: 20px;
  color: #09152f;
  margin-top: 0; }

h3 {
  font-family: "Arvo", Georgia, Times, serif;
  font-size: 17px;
  color: #070f29; }

.pink {
  color: #ff436b; }

.pink-progress {
  background-image: none;
  background-color: #ff436b; }

/* ---------------------------

	GENERAL

--------------------------- */
img {
  max-width: 100%;
  height: auto; }

.no-margin {
  margin: 0 0 0 0; }

.no-padding {
  padding: 0 0 0 0; }

.content-padding {
  padding: 60px 0; }

.theme-color {
  color: #ff436b; }

.grey-bg {
  background: #f5f6f7 !important; }

.text-error {
  color: red; }

.input-error {
  border-width: 1px;
  border-style: solid;
  border-color: red !important; }

/* ---------------------------

	BUTTONS

--------------------------- */
.btn {
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.015);
  box-shadow: none;
  border: none;
  border-radius: 4px;
  text-transform: uppercase; }

.btn-lg {
  font-size: 16px;
  padding: 16px 20px 14px 20px; }

.btn-default {
  background: #ff436b;
  color: #fff; }
  .btn-default:hover, .btn-default:focus {
    background: #e9355b;
    color: #fff; }

.btn-fb {
  background: #596f98;
  color: #fff;
  text-transform: none; }
  .btn-fb:hover, .btn-fb:focus {
    background: #4d6186;
    color: #fff; }

/* ---------------------------

	FORMS

--------------------------- */
form label {
  font-size: 14px;
  font-weight: 400; }

form .help-block {
  font-size: 12px; }

input.form-control {
  font-size: 18px;
  height: 56px;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 1px 1px rgba(12, 62, 72, 0.15); }

select.form-control {
  font-size: 18px;
  height: 56px;
  text-indent: 14px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 1px 1px rgba(12, 62, 72, 0.15);
  max-width: 340px; }

.upload-input {
  margin-bottom: 10px; }
  .upload-input input {
    opacity: 0;
    position: relative;
    z-index: 200; }
  .upload-input .input-placeholder {
    display: block;
    width: 100%;
    background: #fff;
    font-size: 18px;
    height: 56px;
    padding: 0 20px;
    line-height: 56px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0px 1px 1px rgba(12, 62, 72, 0.15);
    margin-top: -58px;
    border-radius: 5px;
    position: relative;
    overflow: hidden; }
    .upload-input .input-placeholder button {
      display: block;
      float: right;
      width: auto;
      height: 44px;
      margin-top: 6px;
      margin-right: 6px;
      position: absolute;
      right: 0;
      top: 0;
      pointer-events: none; }
  .upload-input .progress {
    display: none; }

textarea {
  font-size: 18px !important;
  padding: 20px !important;
  border: 1px solid rgba(0, 0, 0, 0.05) !important;
  box-shadow: 0px 1px 1px rgba(12, 62, 72, 0.15);
  min-height: 250px;
  max-width: 100%; }

/* ---------------------------

	TOP

--------------------------- */
#top {
  display: block;
  float: left;
  width: 100%;
  height: 129px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 400;
  background: url("../img/top-border.png") repeat-x top; }
  #top .logo img {
    width: 274px;
    height: auto;
    margin-top: 6px; }
  @media all and (max-width: 767px) {
    #top .logo img {
      width: 190px;
      margin-top: 20px; } }

/* ---------------------------

	HEADER

--------------------------- */
#header {
  display: block;
  float: left;
  width: 100%;
  height: 874px;
  position: relative; }
  #header .search-holder {
    display: block;
    float: left;
    width: 100%;
    margin-top: 30px; }
    #header .search-holder #search {
      display: block;
      float: left; }
      #header .search-holder #search form {
        display: block;
        float: left;
        width: 100%; }
        #header .search-holder #search form input.search {
          display: block;
          float: left;
          width: 100%;
          height: 55px;
          border-radius: 4px;
          background-color: #fff;
          border: none;
          padding-left: 20px;
          padding-right: 75px;
          border: 1px solid #eee;
          outline: none;
          font-size: 18px; }
        #header .search-holder #search form button {
          display: block;
          float: right;
          margin-top: -55px;
          height: 55px;
          width: 55px;
          background: #ff436b;
          border: none;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          color: #fff;
          position: relative;
          z-index: 110; }
      #header .search-holder #search .search-results {
        display: none;
        float: left;
        width: calc(100% - 55px);
        background: #fff;
        margin-top: 1px;
        border-radius: 4px;
        overflow: hidden;
        padding: 12px 0;
        border: 1px solid #eee;
        position: relative;
        z-index: 500; }
        #header .search-holder #search .search-results ul.results {
          display: block;
          float: left;
          width: 100%;
          list-style: none;
          margin: 0 0 0 0;
          padding: 0 0 0 0; }
          #header .search-holder #search .search-results ul.results li {
            display: block;
            float: left;
            width: 100%; }
            #header .search-holder #search .search-results ul.results li:hover {
              background: #ecf0f3; }
            #header .search-holder #search .search-results ul.results li a {
              display: block;
              float: left;
              width: 100%;
              padding: 10px 26px; }
              #header .search-holder #search .search-results ul.results li a img {
                display: block;
                float: left;
                margin-right: 12px; }
              #header .search-holder #search .search-results ul.results li a:hover {
                text-decoration: none; }
              #header .search-holder #search .search-results ul.results li a h4 {
                font-size: 14px;
                color: #25292c;
                margin: 7px 0; }
              #header .search-holder #search .search-results ul.results li a p {
                margin: 0 0 0 0;
                font-style: italic;
                color: #8a9096;
                font-size: 13px; }
          #header .search-holder #search .search-results ul.results li.active {
            background: #ecf0f3; }
        #header .search-holder #search .search-results.visible {
          display: block; }
  @media all and (max-width: 767px) {
    #header .search-holder {
      margin-top: 150px; } }
  @media all and (max-width: 540px) {
    #header .search-holder {
      margin-top: 150px; }
      #header .search-holder #search {
        margin-left: 0;
        width: 100%; } }
  #header .banner {
    background: rgba(250, 244, 220, 0.81);
    padding: 30px;
    margin-top: 310px; }
    #header .banner h1 {
      font-family: "Arvo", Georgia, Times, serif;
      font-size: 36px;
      margin: 0 0 0 0;
      font-style: italic;
      font-weight: 700; }
    #header .banner h2 {
      font-size: 24px;
      color: #320912;
      font-style: italic;
      font-weight: 300;
      margin: 8px 0 0 0; }
  @media all and (max-width: 991px) {
    #header .banner {
      margin-top: 200px; } }
  #header .login {
    display: block;
    float: left;
    width: 100%;
    margin-top: 30px;
    text-align: center; }
  #header .menu-tabs {
    display: block;
    float: left;
    width: 100%;
    position: absolute;
    bottom: 0; }
    #header .menu-tabs ul.main-menu {
      display: block;
      float: left;
      width: 100%;
      text-align: center;
      margin: 0 0 -5px 0;
      padding: 0 0 0 0;
      list-style: none; }
      #header .menu-tabs ul.main-menu li {
        display: inline-block;
        margin: 0 0 0 0;
        padding: 0 0 0 0; }
        #header .menu-tabs ul.main-menu li a {
          display: block;
          float: left;
          width: 100%;
          background: rgba(255, 255, 255, 0.7);
          padding: 18px 30px;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          font-size: 16px;
          color: #221e1f; }
          #header .menu-tabs ul.main-menu li a:hover {
            background: white;
            text-decoration: none; }
          #header .menu-tabs ul.main-menu li a.active {
            background: white;
            color: #ff436b;
            padding-top: 25px; }
  @media all and (max-width: 1199px) {
    #header .menu-tabs ul.main-menu {
      margin: 0 0 0 0; }
      #header .menu-tabs ul.main-menu li a {
        border-radius: 4px; }
        #header .menu-tabs ul.main-menu li a.active {
          padding-top: 18px; } }
  @media all and (max-width: 540px) {
    #header .menu-tabs {
      margin-bottom: 20px; }
      #header .menu-tabs ul.main-menu li a {
        padding: 9px 8px;
        font-size: 15px; }
        #header .menu-tabs ul.main-menu li a.active {
          padding-top: 9px; } }
  #header.sub-header {
    height: 265px; }
  #header.sub-header-lg {
    height: 530px; }
    #header.sub-header-lg .search-holder {
      margin-top: 235px; }
    @media all and (max-width: 767px) {
      #header.sub-header-lg .search-holder {
        margin-top: 150px; } }

@media all and (max-width: 767px) {
  #header {
    height: 480px; } }

@media all and (min-width: 768px) and (max-width: 991px) {
  #header {
    height: 740px; } }

.social-tab {
  display: block;
  float: left;
  width: 52px;
  background: #fff;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 398px;
  overflow: hidden; }
  .social-tab ul {
    display: block;
    float: left;
    width: 100%;
    margin: 0 0 0 0;
    padding: 25px 0;
    list-style: none; }
    .social-tab ul li {
      display: block;
      float: left;
      width: 100%;
      margin: 0 0 0 0;
      padding: 0 0 0 0; }
      .social-tab ul li a {
        display: block;
        float: left;
        width: 100%;
        font-size: 16px;
        color: #ff436b;
        padding: 7px 0; }
        .social-tab ul li a:hover {
          color: #fff;
          background: #ff436b; }

/* ---------------------------

	MID-SECTION

--------------------------- */
#mid-section {
  display: block;
  float: left;
  width: 100%;
  background-color: #fcfcfc; }
  #mid-section .intro-text {
    display: block;
    float: left;
    width: 100%;
    margin: 50px 0 30px 0;
    padding: 0 15px;
    text-align: center; }
  #mid-section .sub-menu-tabs {
    display: block;
    float: left;
    width: 100%;
    margin: 30px 0; }
    #mid-section .sub-menu-tabs ul.sub-menu {
      display: block;
      float: left;
      width: 100%;
      text-align: center;
      list-style: none;
      margin: 0 0 0 0;
      padding: 0 0 0 0;
      font-size: 0px; }
      #mid-section .sub-menu-tabs ul.sub-menu li {
        display: inline-block;
        margin: 0 0 0 0;
        padding: 0 0 0 0;
        background: #f5f6f7;
        overflow: hidden; }
        #mid-section .sub-menu-tabs ul.sub-menu li a {
          display: block;
          float: left;
          width: 100%;
          font-size: 16px;
          padding: 20px;
          color: #25292c; }
          #mid-section .sub-menu-tabs ul.sub-menu li a.active {
            background-color: #ff436b;
            color: #fff; }
            #mid-section .sub-menu-tabs ul.sub-menu li a.active:hover {
              background-color: #e9355b;
              color: #fff; }
          #mid-section .sub-menu-tabs ul.sub-menu li a:hover {
            background-color: #e8e8e8;
            text-decoration: none; }
        #mid-section .sub-menu-tabs ul.sub-menu li:first-child {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px; }
        #mid-section .sub-menu-tabs ul.sub-menu li:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px; }
  @media all and (max-width: 767px) {
    #mid-section .sub-menu-tabs {
      padding: 15px; }
      #mid-section .sub-menu-tabs ul.sub-menu li {
        border-radius: 0px !important;
        width: 33.33333%; }
        #mid-section .sub-menu-tabs ul.sub-menu li a {
          padding: 10px;
          font-size: 15px; } }
  @media all and (max-width: 650px) {
    #mid-section .sub-menu-tabs {
      padding: 15px; }
      #mid-section .sub-menu-tabs ul.sub-menu li {
        border-radius: 0px;
        width: 50%; } }
  @media all and (max-width: 400px) {
    #mid-section .sub-menu-tabs ul.sub-menu li {
      width: 100%; } }

/* ---------------------------

	Carousel

--------------------------- */
.carousel-wrapper {
  display: block;
  float: left;
  width: 100%;
  padding: 0 8px;
  position: relative; }
  .carousel-wrapper .carousel {
    display: none;
    float: left;
    width: 100%;
    height: auto;
    margin: 30px 0;
    padding: 0 0 0 0; }
    .carousel-wrapper .carousel .carousel-item {
      display: block;
      float: left;
      width: auto;
      margin: 0 0 0 0;
      padding: 8px; }
      .carousel-wrapper .carousel .carousel-item img {
        border-radius: 4px;
        width: 100%;
        height: auto; }
      .carousel-wrapper .carousel .carousel-item h2 {
        font-family: "Helvatica Neue", Helvetica, Arial, sans-serif;
        font-size: 16px;
        color: #ff436b;
        font-weight: bold;
        margin: 10px 0; }
      .carousel-wrapper .carousel .carousel-item:hover img {
        opacity: .8; }
      .carousel-wrapper .carousel .carousel-item:hover h2 {
        color: #e9355b; }
  .carousel-wrapper .carousel-pager {
    position: absolute;
    top: 50%;
    margin-top: -20px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #fcfcfd;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
    color: #25292c; }
    .carousel-wrapper .carousel-pager.pager-left {
      left: 0;
      margin-left: -10px; }
    .carousel-wrapper .carousel-pager.pager-right {
      right: 0;
      margin-right: -10px; }
  @media all and (max-width: 767px) {
    .carousel-wrapper .carousel-pager.pager-left {
      margin-left: 0px; }
    .carousel-wrapper .carousel-pager.pager-right {
      margin-right: 0px; } }

/* ---------------------------

	MAIN-CONTENT

--------------------------- */
#main-content {
  display: block;
  float: left;
  width: 100%;
  background: #fff;
  padding: 50px 0; }

/* ---------------------------

	BOX

--------------------------- */
.box {
  display: block;
  float: left;
  width: 100%;
  margin-bottom: 20px; }
  .box .box-label {
    display: block;
    float: left;
    padding: 14px 30px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    font-size: 16px;
    font-family: "Arvo", Georgia, Times, serif; }
  .box .box-content {
    display: block;
    float: left;
    padding: 24px 30px;
    width: 100%;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px; }
  .box.grey .box-label {
    background: #f5f6f7; }
  .box.grey .box-content {
    background: #f5f6f7; }
  .box p {
    color: #8a9096; }

.user-box {
  display: block;
  float: left;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px; }
  .user-box .top {
    display: block;
    float: left;
    width: 100%;
    text-align: center;
    padding: 0 30px 30px 30px;
    background: #eff1f4;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; }
    .user-box .top .user-image {
      width: 90px;
      height: 90px;
      border-radius: 4px;
      margin-top: -20px; }
    .user-box .top h2 {
      padding-top: 25px;
      font-size: 16px; }
      .user-box .top h2 .pink {
        font-size: 14px; }
  .user-box .content {
    display: block;
    float: left;
    width: 100%;
    padding: 30px;
    background: #f5f6f7;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px; }
    .user-box .content .user-links {
      display: block;
      float: left;
      width: 100%;
      list-style: none;
      margin: 0 0 0 0;
      padding: 0 0 0 0; }
      .user-box .content .user-links li {
        display: block;
        float: left;
        width: 100%;
        margin: 0 0 0 0;
        padding: 0 0 0 0; }
        .user-box .content .user-links li i {
          color: #ff436b;
          font-size: 10px; }
        .user-box .content .user-links li a {
          color: #25292c;
          line-height: 22px; }
          .user-box .content .user-links li a.pink {
            color: #ff436b; }
          .user-box .content .user-links li a:hover {
            text-decoration: underline; }

/* ---------------------------

	THUMB-LIST

--------------------------- */
.thumb-list {
  display: block;
  float: left;
  width: 100%;
  list-style: none;
  margin: 0 0 0 0;
  padding: 0 0 0 0; }
  .thumb-list > li {
    display: block;
    float: left;
    width: 100%;
    padding: 6px 0; }
    .thumb-list > li a {
      color: #25292c;
      font-size: 14px; }
      .thumb-list > li a .media .media-left {
        display: block; }
        .thumb-list > li a .media .media-left img {
          display: block;
          float: left;
          width: 68px;
          height: 68px;
          border-radius: 3px;
          margin: 0 10px 0 0; }
        @media all and (max-width: 1199px) {
          .thumb-list > li a .media .media-left img {
            width: 50px;
            height: 50px; } }
        @media all and (max-width: 991px) {
          .thumb-list > li a .media .media-left img {
            width: 68px;
            height: 68px; } }
      .thumb-list > li a .media .media-body .recipe-name {
        display: block; }
      .thumb-list > li a .media .media-body .by {
        display: block;
        font-size: 13px;
        color: #8a9096;
        font-style: italic; }
      .thumb-list > li a .media .media-body .date {
        display: block;
        font-size: 13px;
        color: #8a9096;
        font-style: italic; }
    .thumb-list > li:hover img {
      opacity: .8; }

/* ---------------------------

	POLL-FORM

--------------------------- */
.poll-form {
  display: block;
  float: left;
  width: 100%; }
  .poll-form button {
    margin-top: 10px; }

/* ---------------------------

	ADS-BLOCK

--------------------------- */
.lysing-block {
  display: block;
  float: left;
  width: 100%;
  margin-bottom: 20px; }
  .lysing-block span.lysing-description {
    display: block;
    float: left;
    width: 100%;
    font-family: "Arvo", Georgia, Times, serif;
    font-size: 13px;
    color: #969ca8;
    padding-bottom: 10px; }
  .lysing-block ul.lysing {
    display: block;
    float: left;
    width: 100%;
    list-style: none;
    margin: 0 0 0 0;
    padding: 0 0 0 0; }
    .lysing-block ul.lysing li {
      display: block;
      float: left;
      width: 100%;
      margin: 10px 0;
      padding: 0 0 0 0; }
      .lysing-block ul.lysing li:first-child {
        margin-top: 0; }
      .lysing-block ul.lysing li:last-child {
        margin-bottom: 0; }
      .lysing-block ul.lysing li img {
        width: 100%;
        height: auto;
        border-radius: 4px; }

/* ---------------------------

	RECIPES-LIST

--------------------------- */
.recipes-list {
  display: block;
  float: left;
  width: calc( 100% + 30px);
  margin: 15px 0 15px -15px;
  list-style: none;
  padding: 0 0 0 0; }
  .recipes-list .item {
    display: block;
    float: left;
    padding-top: 15px;
    padding-bottom: 15px; }
    .recipes-list .item > a .image {
      display: block;
      float: left;
      width: 100%;
      overflow: hidden;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      position: relative; }
      .recipes-list .item > a .image img {
        width: 100%;
        height: auto; }
      .recipes-list .item > a .image .hover {
        display: block;
        float: left;
        width: 100%;
        height: 100%;
        position: absolute;
        background: rgba(255, 67, 107, 0.8);
        top: 0;
        left: 0;
        text-align: center;
        opacity: 0; }
        .recipes-list .item > a .image .hover i {
          font-size: 30px;
          color: #fff;
          position: relative;
          top: 42%; }
    .recipes-list .item > a .desc {
      display: block;
      float: left;
      width: 100%;
      background: #f5f6f7;
      overflow: hidden;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      padding: 20px; }
      .recipes-list .item > a .desc h3 {
        font-size: 17px;
        color: #25292c;
        margin: 0 0 4px 0; }
      .recipes-list .item > a .desc .category {
        font-size: 15px;
        color: #ff436b; }
      .recipes-list .item > a .desc .rating {
        display: block;
        float: right;
        margin-top: 4px; }
        .recipes-list .item > a .desc .rating i {
          display: block;
          float: left;
          font-size: 13px;
          padding: 0 1px;
          color: #ff436b; }
    .recipes-list .item > a:hover .image img {
      opacity: .8; }
    .recipes-list .item > a:hover .image .hover {
      opacity: 1; }

.more-recipes-bar-btn {
  display: block;
  float: left;
  width: 100%;
  text-align: right;
  padding: 15px 20px;
  background: #f5f6f7;
  color: #ff436b;
  border-radius: 4px;
  font-size: 14px; }
  .more-recipes-bar-btn:hover {
    color: #e9355b;
    background: #dfdfdf; }
  .more-recipes-bar-btn i {
    font-size: 12px; }

/* ---------------------------

	RECOMMENDED

--------------------------- */
#recommended {
  display: block;
  float: left;
  width: 100%;
  background: #f5f6f7; }
  #recommended h2.sectionTitle {
    margin-bottom: 0; }

/* ---------------------------

	FOOTER

--------------------------- */
#footer {
  display: block;
  float: left;
  width: 100%;
  background: #2a2b2c;
  color: #f9fafb; }
  #footer .footer-logo img {
    max-width: 100%;
    height: auto;
    margin-top: 20px; }
  #footer h4 {
    font-size: 17px; }
  #footer ul.footer-links {
    display: block;
    float: left;
    width: 100%;
    list-style: none;
    margin: 0 0 30px 0;
    padding: 0 0 0 0; }
    #footer ul.footer-links li {
      padding: 3px 0; }
      #footer ul.footer-links li a {
        color: #f9fafb; }
        #footer ul.footer-links li a:hover {
          text-decoration: underline; }
  @media all and (max-width: 991px) {
    #footer .footer-logo {
      text-align: center; }
      #footer .footer-logo img {
        width: 200px;
        margin-top: 0;
        margin-bottom: 30px; } }

/* ---------------------------

	LEGAL-FOOTER

--------------------------- */
#legal-footer {
  display: block;
  float: left;
  width: 100%;
  color: #abb0b4;
  font-size: 12px;
  padding: 28px 0; }
  #legal-footer .social-footer {
    display: block;
    float: left;
    width: 100%;
    text-align: center;
    margin: 0 0 0 0;
    padding: 4px 0 0 0;
    list-style: none; }
    #legal-footer .social-footer li {
      display: inline-block;
      padding: 0 3px;
      margin: 0 0 0 0; }
      #legal-footer .social-footer li a {
        font-size: 17px;
        color: #abb0b4; }
        #legal-footer .social-footer li a:hover {
          color: #404040; }

/* ---------------------------

	SUB-PAGE: SEARCH RESULTS

--------------------------- */
#search-results .search-holder {
  display: block;
  float: left;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 30px; }
  #search-results .search-holder #search {
    display: block;
    float: left; }
    #search-results .search-holder #search form {
      display: block;
      float: left;
      width: 100%; }
      #search-results .search-holder #search form input.search {
        display: block;
        float: left;
        width: 100%;
        height: 55px;
        border-radius: 4px;
        background-color: #f5f6f7;
        border: none;
        padding-left: 20px;
        padding-right: 75px;
        border: 1px solid #eee;
        outline: none; }
      #search-results .search-holder #search form button {
        display: block;
        float: right;
        margin-top: -55px;
        height: 55px;
        width: 55px;
        background: #ff436b;
        border: none;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        color: #fff;
        position: relative;
        z-index: 110; }

#search-results .recipes-list {
  margin-top: 50px; }

/* ---------------------------

	PAGINATION

--------------------------- */
.pagination {
  display: block;
  float: left;
  width: 100%;
  background: #f5f6f7;
  border-radius: 4px;
  padding: 25px 15px;
  text-align: center; }
  .pagination ul.pages {
    display: block;
    float: left;
    width: 100%;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    list-style: none; }
    .pagination ul.pages li {
      display: inline-block;
      padding: 0 1px;
      margin: 0 0 0 0; }
      .pagination ul.pages li a {
        display: block;
        float: left;
        width: 32px;
        height: 34px;
        border-radius: 4px;
        border: 1px solid rgba(205, 205, 205, 0.8);
        line-height: 34px;
        text-align: center;
        color: #919191;
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.05);
        background: #fff; }
        .pagination ul.pages li a:hover {
          background: #ccc;
          color: #fff; }
      .pagination ul.pages li.active a {
        background: #ff436b;
        color: #fff; }
      .pagination ul.pages li.active:hover a {
        background: #e9355b;
        color: #fff; }
      .pagination ul.pages li.previous {
        padding-right: 15px; }
      .pagination ul.pages li.next {
        padding-left: 15px; }

/* ---------------------------

	RECIPE DETAILS

--------------------------- */
#recipe-details .user-recipe {
  margin-bottom: 15px;
  padding: 15px;
  margin-top: 20px;
  background: #f5f6f7;
  border-radius: 4px; }
  #recipe-details .user-recipe .user-btns {
    display: block;
    float: right;
    list-style: none;
    margin: -5px 0 0 0;
    padding: 0 0 0 0;
    width: auto; }
    #recipe-details .user-recipe .user-btns li {
      display: block;
      float: left;
      margin-left: 5px; }
      #recipe-details .user-recipe .user-btns li a {
        text-transform: none; }

#recipe-details .recipe-name-and-recipe-rate {
  margin-bottom: 20px; }
  #recipe-details .recipe-name-and-recipe-rate .recipe-name {
    float: left; }
  #recipe-details .recipe-name-and-recipe-rate .recipe-rate {
    float: right;
    margin-top: -5px; }
    #recipe-details .recipe-name-and-recipe-rate .recipe-rate .rating-box {
      display: inline-block;
      float: left;
      width: 100%;
      color: #6d7378; }
      #recipe-details .recipe-name-and-recipe-rate .recipe-rate .rating-box span {
        float: right;
        margin-right: 15px; }
      #recipe-details .recipe-name-and-recipe-rate .recipe-rate .rating-box fieldset {
        margin-right: 15px; }

#recipe-details .chef-tab {
  display: block;
  float: left;
  width: 100%;
  position: relative; }
  #recipe-details .chef-tab p {
    margin-bottom: 0;
    line-height: 16px; }
  #recipe-details .chef-tab .user-thumb {
    display: block;
    float: left;
    margin: 0 8px 8px 0;
    border-radius: 4px; }
  #recipe-details .chef-tab .uploaded-by {
    padding-top: 4px;
    font-family: "Arvo", Georgia, Times, serif;
    font-size: 15px;
    line-height: 20px; }
  #recipe-details .chef-tab .date {
    font-family: "Arvo", Georgia, Times, serif;
    font-size: 12px; }
  #recipe-details .chef-tab .category {
    font-family: "Arvo", Georgia, Times, serif;
    font-size: 12px; }
  #recipe-details .chef-tab .votes {
    font-family: "Arvo", Georgia, Times, serif;
    font-size: 12px; }
    #recipe-details .chef-tab .votes .text {
      float: left;
      margin-right: 2px; }
    #recipe-details .chef-tab .votes .rating {
      font-size: 20px; }
      #recipe-details .chef-tab .votes .rating i {
        display: block;
        float: left;
        font-size: 13px;
        padding: 0 1px;
        margin-top: 1px;
        color: #ff436b; }

#recipe-details ul.print-share {
  display: block;
  float: right;
  margin: 8px -2px 0 0;
  padding: 0 0 0 0;
  list-style: none; }
  #recipe-details ul.print-share li {
    display: block;
    float: left;
    margin: 0 0 0 0;
    padding: 2px; }
    #recipe-details ul.print-share li a {
      font-size: 12px; }

@media all and (max-width: 767px) {
  #recipe-details ul.print-share {
    margin-bottom: 15px; } }

#recipe-details .ingredients {
  padding: 0 30px 0 0;
  margin-top: 30px; }
  #recipe-details .ingredients h2 {
    margin-bottom: 30px; }
  #recipe-details .ingredients p {
    margin-bottom: 7px; }

#recipe-details .information {
  display: block;
  float: left;
  width: 100%;
  border-top: 1px dotted #d1d1d1;
  padding: 15px 0;
  margin: 15px 0; }
  #recipe-details .information .table {
    margin-bottom: 0; }
  #recipe-details .information .table > tbody > tr > td {
    border-top: none; }

#recipe-details .method {
  padding: 0 0 0 30px;
  margin-top: 30px;
  border-left: 1px dotted #d1d1d1; }
  #recipe-details .method h2 {
    margin-bottom: 30px; }
  #recipe-details .method p {
    line-height: 25px;
    font-weight: 300; }

@media all and (max-width: 767px) {
  #recipe-details .ingredients {
    padding: 0 0 30px 0;
    border-bottom: 1px dotted #d1d1d1; }
  #recipe-details .method {
    padding: 30px 0 0 0;
    border-left: none; } }

#recipe-slider-wrapper {
  display: none;
  float: left;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 0px;
  position: relative; }
  #recipe-slider-wrapper ul.recipe-slider {
    display: block;
    float: left;
    width: 100%;
    list-style: none;
    padding: 0 0 0 0;
    margin: 0 0 0 0; }
    #recipe-slider-wrapper ul.recipe-slider li {
      display: block;
      float: left;
      width: 100%;
      padding: 0 0 0 0;
      margin: 0 0 0 0; }
      #recipe-slider-wrapper ul.recipe-slider li img {
        border-radius: 4px; }
  #recipe-slider-wrapper .recipe-pager {
    display: block;
    position: absolute;
    top: 35%; }
    #recipe-slider-wrapper .recipe-pager a {
      display: block;
      float: left;
      background: #f5f6f7;
      line-height: 104px;
      text-align: center;
      font-size: 16px;
      color: #25292c;
      width: 33px;
      height: 104px;
      cursor: pointer; }
    #recipe-slider-wrapper .recipe-pager.recipe-slider-prev {
      left: 0; }
      #recipe-slider-wrapper .recipe-pager.recipe-slider-prev a {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px; }
    #recipe-slider-wrapper .recipe-pager.recipe-slider-next {
      right: 0; }
      #recipe-slider-wrapper .recipe-pager.recipe-slider-next a {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px; }
    #recipe-slider-wrapper .recipe-pager:hover a {
      background: #ff436b;
      color: #fff; }
  #recipe-slider-wrapper .recipe-slider-pager {
    display: block;
    float: left;
    margin-top: -80px;
    margin-left: 30px;
    position: relative;
    z-index: 800; }
    #recipe-slider-wrapper .recipe-slider-pager a {
      display: block;
      float: left;
      padding-right: 10px; }
      #recipe-slider-wrapper .recipe-slider-pager a img {
        width: 50px;
        height: 50px;
        border-radius: 4px; }
      #recipe-slider-wrapper .recipe-slider-pager a.active img {
        border: 2px solid #fff; }
  @media all and (max-width: 767px) {
    #recipe-slider-wrapper .recipe-slider-pager {
      display: none; }
    #recipe-slider-wrapper .recipe-pager {
      display: none; } }

/* ---------------------------

	COMMENT

--------------------------- */
#write-comment {
  display: block;
  float: left;
  width: 100%;
  background: #f5f6f7;
  padding: 30px;
  border-radius: 4px; }
  #write-comment h2 {
    margin: 0 0 10px 0;
    color: #ff436b; }
  #write-comment textarea {
    min-height: 120px;
    margin-bottom: 15px; }
  #write-comment textarea {
    background: #fff;
    border: none !important;
    box-shadow: none !important;
    padding: 20px !important;
    max-width: 100%; }

#recipe-details #write-comment {
  margin-top: 50px; }

/* ---------------------------

	RATING

--------------------------- */
.rating-box {
  /****** Style Star Rating Widget *****/
  /***** CSS Magic to Highlight Stars on Hover *****/
  /* hover previous stars in list */ }
  .rating-box fieldset, .rating-box label {
    margin: 0;
    padding: 0; }
  .rating-box .rating {
    border: none;
    float: left; }
  .rating-box .rating > input {
    display: none; }
  .rating-box .rating > label:before {
    margin: 2px;
    font-size: 22px;
    font-family: FontAwesome;
    display: inline-block;
    content: "\f005"; }
  .rating-box .rating > .half:before {
    content: "\f089";
    position: absolute; }
  .rating-box .rating > label {
    color: #ddd;
    float: right; }
  .rating-box .rating > input:checked ~ label,
  .rating-box .rating:not(:checked) > label:hover,
  .rating-box .rating:not(:checked) > label:hover ~ label {
    color: #ff436b; }
  .rating-box .rating > input:checked + label:hover,
  .rating-box .rating > input:checked ~ label:hover,
  .rating-box .rating > label:hover ~ input:checked ~ label,
  .rating-box .rating > input:checked ~ label:hover ~ label {
    color: #ff436b; }

/* ---------------------------

	COMMENTS

--------------------------- */
#comments {
  display: block;
  float: left;
  width: 100%;
  margin: 50px 0 0 0; }
  #comments h2 {
    color: #ff436b; }
  #comments ul.comment-stream {
    width: 100%;
    list-style: none;
    margin: 15px 0 50px 0;
    padding: 0 0 0 0; }
    #comments ul.comment-stream li.comment {
      width: 100%;
      padding: 30px;
      border-radius: 4px;
      margin: 0 0 0 0;
      overflow: hidden; }
      #comments ul.comment-stream li.comment:nth-child(odd) {
        background: #f5f6f7; }
      #comments ul.comment-stream li.comment .media-left {
        padding-right: 20px; }
        #comments ul.comment-stream li.comment .media-left .media-object {
          border-radius: 4px;
          width: 92px;
          height: 92px;
          max-width: none; }
        @media all and (max-width: 767px) {
          #comments ul.comment-stream li.comment .media-left .media-object {
            width: 68px;
            height: 68px; } }
      @media all and (max-width: 767px) {
        #comments ul.comment-stream li.comment .media-left {
          padding-right: 10px; } }
      #comments ul.comment-stream li.comment .media-body .name {
        display: block;
        float: left;
        width: 100%;
        font-size: 16px;
        color: #ff436b;
        font-weight: 700; }
      #comments ul.comment-stream li.comment .media-body .date {
        display: block;
        float: left;
        width: 100%;
        font-size: 13px;
        color: #8f969d; }
      #comments ul.comment-stream li.comment .media-body p {
        display: block;
        float: left;
        width: 100%;
        margin-top: 8px; }

/* ---------------------------

	UPLOAD

--------------------------- */
#upload-recipe {
  display: block;
  float: left;
  margin-bottom: 20px; }
  #upload-recipe h1 {
    margin-top: 0px; }
  #upload-recipe .info {
    display: block;
    float: left;
    width: 100%;
    padding: 30px;
    background: #ff436b;
    color: #fff;
    border-radius: 4px;
    margin: 15px 0 40px 0;
    font-family: "Arvo", Georgia, Times, serif; }
    #upload-recipe .info p {
      color: #fff;
      line-height: 25px; }
      #upload-recipe .info p i {
        font-size: 12px; }
      #upload-recipe .info p:last-child {
        margin-bottom: 0; }
    #upload-recipe .info b {
      font-weight: 700; }
  #upload-recipe h2 {
    margin-bottom: 15px;
    margin-top: 20px; }
  #upload-recipe form .more-photos-upload {
    display: block;
    float: left;
    padding-top: 40px; }
  #upload-recipe form .upload-button {
    margin-top: 50px; }
  #upload-recipe .uploaded-image {
    display: block;
    float: left;
    width: 68px;
    height: auto;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-top: 0px;
    margin-left: 40px; }
  #upload-recipe .delete-uploaded-image {
    display: block;
    float: left;
    font-size: 24px;
    color: #b7b7b7;
    margin-top: 40px;
    margin-left: 24px; }
    #upload-recipe .delete-uploaded-image:hover {
      color: #e9355b; }

/* ---------------------------

    FAVORITES AND MY RECIPES

---------------------------- */
.info {
  display: block;
  float: left;
  width: 100%;
  padding: 30px;
  background: #ff436b;
  color: #fff;
  border-radius: 4px;
  font-family: "Arvo", Georgia, Times, serif; }
  .info p {
    color: #fff;
    line-height: 25px; }
    .info p i {
      font-size: 12px; }
    .info p:last-child {
      margin-bottom: 0; }
  .info b {
    font-weight: 700; }

/* ---------------------------

	MAP

--------------------------- */
#map {
  display: block;
  float: left;
  width: 100%; }
  #map iframe {
    width: 100%;
    height: 500px; }
  @media all and (max-width: 767px) {
    #map iframe {
      height: 250px; } }

/* ---------------------------

	CONTACT

--------------------------- */
#contact-us form#contact-form {
  margin: 30px 0; }
  #contact-us form#contact-form .send-message {
    margin-top: 30px; }

/* ---------------------------

	TESTIMONIALS

--------------------------- */
#testimonials {
  display: block;
  float: left;
  width: 100%; }
  #testimonials #testimonials-slider-wrapper {
    display: none;
    margin-top: 50px;
    position: relative; }
    #testimonials #testimonials-slider-wrapper ul.testimonials-slider {
      display: block;
      float: left;
      width: 100%;
      list-style: none;
      margin: 0 0 0 0;
      padding: 0 0 0 0; }
      #testimonials #testimonials-slider-wrapper ul.testimonials-slider li  {
        margin: 0 0 0 0;
        padding: 0 0 0 0;
        text-align: center; }
        #testimonials #testimonials-slider-wrapper ul.testimonials-slider li  img {
          border-radius: 4px; }
        #testimonials #testimonials-slider-wrapper ul.testimonials-slider li  h3 {
          font-size: 24px;
          font-family: "Helvatica Neue", Helvetica, Arial, sans-serif;
          margin-bottom: 5px; }
        #testimonials #testimonials-slider-wrapper ul.testimonials-slider li  p {
          font-size: 16px;
          font-weight: 300; }
          #testimonials #testimonials-slider-wrapper ul.testimonials-slider li  p b {
            font-size: 18px;
            font-weight: 400; }
    #testimonials #testimonials-slider-wrapper .testimonials-pager {
      display: block;
      float: left;
      position: absolute;
      top: 0;
      margin-top: 120px;
      font-size: 20px; }
      #testimonials #testimonials-slider-wrapper .testimonials-pager.testimonials-slider-prev {
        left: 0;
        margin-left: -100px; }
      #testimonials #testimonials-slider-wrapper .testimonials-pager.testimonials-slider-next {
        right: 0;
        margin-right: -100px; }
    @media all and (max-width: 1169px) {
      #testimonials #testimonials-slider-wrapper .testimonials-pager {
        margin-top: 95px; } }
    @media all and (max-width: 969px) {
      #testimonials #testimonials-slider-wrapper .testimonials-pager.testimonials-slider-prev {
        margin-left: -20px; }
      #testimonials #testimonials-slider-wrapper .testimonials-pager.testimonials-slider-next {
        margin-right: -20px; } }
    @media all and (max-width: 800px) {
      #testimonials #testimonials-slider-wrapper .testimonials-pager {
        display: none; } }
    #testimonials #testimonials-slider-wrapper .testimonials-slider-pager {
      display: block;
      float: left;
      width: 100%;
      text-align: center;
      margin-top: 30px; }
      #testimonials #testimonials-slider-wrapper .testimonials-slider-pager a {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #d9e0e2;
        margin: 0 1px; }
        #testimonials #testimonials-slider-wrapper .testimonials-slider-pager a.active {
          background: #ff436b; }
