/* ---------------------------

	GOOGLE FONTS

--------------------------- */

@import url(https://fonts.googleapis.com/css?family=Arvo:400,700,400italic,700italic);


/* ---------------------------

	GLOBALS

--------------------------- */

$primary-font: 'Helvatica Neue', Helvetica, Arial, sans-serif;
$font-size: 14px;

$secondary-font: 'Arvo', Georgia, Times, serif;

$primary-color: #25292c;
$theme-color: #ff436b;
$theme-color-hover: #e9355b;
$grey-bg: #f5f6f7;


/* ---------------------------

	MAINS

--------------------------- */

body { font-family: $primary-font; font-size: $font-size; color: #25292c; font-weight: 400; }


/* ---------------------------

	TYPOGRAPHY

--------------------------- */

a { color: $theme-color;
    &:hover { text-decoration: none; }
}

p { color: $primary-color; font-size: 15px; }

h1 { font-family: $secondary-font; color: #320912; font-weight: 400; font-size: 27px; }

h2 { font-family: $secondary-font; font-size: 20px; color: #09152f; margin-top: 0; }

h3 { font-family: $secondary-font; font-size: 17px; color: #070f29; }

.pink { color: $theme-color; }

.pink-progress { background-image: none; background-color: $theme-color; }


/* ---------------------------

	GENERAL

--------------------------- */

img { max-width: 100%; height: auto; }

.no-margin { margin: 0 0 0 0; }
.no-padding { padding: 0 0 0 0; }

.content-padding { padding: 60px 0; }

.theme-color { color: $theme-color; }

.grey-bg { background: #f5f6f7 !important; }

.text-error { color: red; }
.input-error {
    border-width: 1px;
    border-style: solid;
    border-color: red !important;
}

/* ---------------------------

	BUTTONS

--------------------------- */

.btn { text-shadow: 0 1px 0 rgba(0,0,0,.015); box-shadow: none; border: none; border-radius: 4px; text-transform: uppercase; }

.btn-lg { font-size: 16px; padding: 16px 20px 14px 20px; }

.btn-default { background: $theme-color; color: #fff;
    &:hover, &:focus { background: $theme-color-hover; color: #fff; }
}

.btn-fb { background: #596f98; color: #fff; text-transform: none;
    &:hover, &:focus { background: #4d6186; color: #fff; }
}


/* ---------------------------

	FORMS

--------------------------- */

form {
    label { font-size: 14px; font-weight: 400; }
    .help-block { font-size: 12px; }
}

input.form-control { font-size: 18px; height: 56px; padding: 20px; border: 1px solid rgba(0,0,0,.05); box-shadow: 0px 1px 1px rgba(12,62,72,.15); }
select.form-control { font-size: 18px; height: 56px; text-indent: 14px; border: 1px solid rgba(0,0,0,.05); box-shadow: 0px 1px 1px rgba(12,62,72,.15); max-width: 340px; }

.upload-input { margin-bottom: 10px;
    input { opacity: 0; position: relative; z-index: 200; }
    .input-placeholder { display: block; width: 100%; background: #fff; font-size: 18px; height: 56px; padding: 0 20px; line-height: 56px; border: 1px solid rgba(0,0,0,.05); box-shadow: 0px 1px 1px rgba(12,62,72,.15); margin-top: -58px; border-radius: 5px; position: relative; overflow: hidden;
        button { display: block; float: right; width: auto; height: 44px; margin-top: 6px; margin-right: 6px; position: absolute; right: 0; top: 0; pointer-events: none; }
    }
    .progress { display: none; }
}

textarea { font-size: 18px !important; padding: 20px !important; border: 1px solid rgba(0,0,0,.05) !important; box-shadow: 0px 1px 1px rgba(12,62,72,.15); min-height: 250px; max-width: 100%; }


/* ---------------------------

	TOP

--------------------------- */

#top { display: block; float: left; width: 100%; height: 129px; position: absolute; top: 0; left: 0; z-index: 400; background: url('../img/top-border.png') repeat-x top;

    .logo {
        img { width: 274px; height: auto; margin-top: 6px; }
    }

    @media all and (max-width: 767px) {
        .logo {
            img { width: 190px; margin-top: 20px; }
        }
    }

}


/* ---------------------------

	HEADER

--------------------------- */

#header { display: block; float: left; width: 100%; height: 874px; position: relative;

    .search-holder { display: block; float: left; width: 100%; margin-top: 30px;
        #search { display: block; float: left;
            form { display: block; float: left; width: 100%;
                input.search { display: block; float: left; width: 100%; height: 55px; border-radius: 4px; background-color: #fff; border: none; padding-left: 20px; padding-right: 75px; border: 1px solid #eee; outline: none; font-size: 18px; }
                button { display: block; float: right; margin-top: -55px; height: 55px; width: 55px; background: $theme-color; border: none; border-top-right-radius: 4px; border-bottom-right-radius: 4px; color: #fff; position: relative; z-index: 110; }
            }
            .search-results { display: none; float: left; width: calc(100% - 55px); background: #fff; margin-top: 1px; border-radius: 4px; overflow: hidden; padding: 12px 0; border: 1px solid #eee; position: relative; z-index: 500;
                ul.results { display: block; float: left; width: 100%; list-style: none; margin: 0 0 0 0; padding: 0 0 0 0;
                    li { display: block; float: left; width: 100%;
                        &:hover { background: #ecf0f3; }
                        a { display: block; float: left; width: 100%; padding: 10px 26px;
                            img { display: block; float: left; margin-right: 12px; }
                            &:hover { text-decoration: none; }
                            h4 { font-size: 14px; color: #25292c; margin: 7px 0; }
                            p { margin: 0 0 0 0; font-style: italic; color: #8a9096; font-size: 13px; }
                        }
                    }

                    li.active { background: #ecf0f3; }
                }
                &.visible { display: block; }
            }
        }
    }

    @media all and (max-width: 767px) {
        .search-holder { margin-top: 150px; }
    }
    @media all and (max-width: 540px) {
        .search-holder { margin-top: 150px;
            #search { margin-left: 0; width: 100%; }
        }
    }

    .banner { background: rgba(250,244,220,.81); padding: 30px; margin-top: 310px;
        h1 { font-family: $secondary-font; font-size: 36px; margin: 0 0 0 0; font-style: italic; font-weight: 700; }
        h2 { font-size: 24px; color: #320912; font-style: italic; font-weight: 300; margin: 8px 0 0 0; }
    }

    @media all and (max-width: 991px) {
        .banner { margin-top: 200px; }
    }

    .login { display: block; float: left; width: 100%; margin-top: 30px; text-align: center; }
    .menu-tabs { display: block; float: left; width: 100%; position: absolute; bottom: 0;
        ul.main-menu { display: block; float:left; width: 100%; text-align: center; margin: 0 0 -5px 0; padding: 0 0 0 0; list-style: none;
            li { display: inline-block; margin: 0 0 0 0; padding: 0 0 0 0;
                a { display: block; float: left; width: 100%; background: rgba(255,255,255,0.7); padding: 18px 30px; border-top-left-radius: 4px; border-top-right-radius: 4px; font-size: 16px; color: #221e1f;
                    &:hover { background: rgba(255,255,255,1); text-decoration: none; }
                    &.active { background: rgba(255,255,255,1); color: $theme-color; padding-top: 25px; }
                }
            }
        }
    }

    @media all and (max-width: 1199px) {
        .menu-tabs {
            ul.main-menu { margin: 0 0 0 0;
                li {
                    a { border-radius: 4px;
                        &.active { padding-top: 18px; }
                    }
                }
            }
        }
    }
    @media all and (max-width: 540px) {
        .menu-tabs { margin-bottom: 20px;
            ul.main-menu {
                li {
                    a { padding: 9px 8px; font-size: 15px;
                        &.active { padding-top: 9px; }
                    }
                }
            }
        }
    }

    &.sub-header { height: 265px; }
    &.sub-header-lg { height: 530px;
        .search-holder { margin-top: 235px; }
        @media all and (max-width: 767px) {
            .search-holder { margin-top: 150px; }
        }
    }

}

@media all and (max-width: 767px) {
    #header { height: 480px; }
}
@media all and (min-width: 768px) and (max-width: 991px) {
    #header { height: 740px; }
}

.social-tab { display: block; float: left; width: 52px; background: #fff; border-top-right-radius: 8px; border-bottom-right-radius: 8px; text-align: center; position: absolute; left: 0; top: 398px; overflow: hidden;
    ul { display: block; float: left; width: 100%; margin: 0 0 0 0; padding: 25px 0; list-style: none;
        li { display: block; float: left; width: 100%; margin: 0 0 0 0; padding: 0 0 0 0;
            a { display: block; float: left; width: 100%; font-size: 16px; color: $theme-color; padding: 7px 0;
                &:hover { color: #fff; background: $theme-color; }
            }
        }
    }
}


/* ---------------------------

	MID-SECTION

--------------------------- */

#mid-section { display: block; float: left; width: 100%; background-color: #fcfcfc;

    .intro-text { display: block; float: left; width: 100%; margin: 50px 0 30px 0; padding: 0 15px; text-align: center; }

    .sub-menu-tabs { display: block; float: left; width: 100%; margin: 30px 0;
        ul.sub-menu { display: block; float: left; width: 100%; text-align: center; list-style: none; margin: 0 0 0 0; padding: 0 0 0 0; font-size: 0px;
            li { display: inline-block; margin: 0 0 0 0; padding: 0 0 0 0; background: #f5f6f7; overflow: hidden;
                a { display: block; float: left; width: 100%; font-size: 16px; padding: 20px; color: $primary-color;
                    &.active { background-color: $theme-color; color: #fff;
                        &:hover { background-color: $theme-color-hover; color: #fff; }
                    }
                    &:hover { background-color: #e8e8e8; text-decoration: none; }
                }
                &:first-child { border-top-left-radius: 4px; border-bottom-left-radius: 4px; }
                &:last-child { border-top-right-radius: 4px; border-bottom-right-radius: 4px; }
            }
        }
    }

    @media all and (max-width: 767px) {
        .sub-menu-tabs { padding: 15px;
            ul.sub-menu {
                li { border-radius: 0px !important; width: 33.33333%;
                    a { padding: 10px; font-size: 15px; }
                }
            }
        }
    }
    @media all and (max-width: 650px) {
        .sub-menu-tabs { padding: 15px;
            ul.sub-menu {
                li { border-radius: 0px; width: 50%; }
            }
        }
    }
    @media all and (max-width: 400px) {
        .sub-menu-tabs {
            ul.sub-menu {
                li { width: 100%; }
            }
        }
    }

}


/* ---------------------------

	Carousel

--------------------------- */

.carousel-wrapper { display: block; float: left; width: 100%; padding: 0 8px; position: relative;
    .carousel { display: none; float: left; width: 100%; height: auto; margin: 30px 0; padding: 0 0 0 0;
        .carousel-item { display: block; float: left; width: auto; margin: 0 0 0 0; padding: 8px;
            img { border-radius: 4px; width: 100%; height: auto; }
            h2 { font-family: $primary-font; font-size: 16px; color: $theme-color; font-weight: bold; margin: 10px 0; }
            &:hover {
                img { opacity: .8; }
                h2 { color: $theme-color-hover; }
            }
        }
    }

    .carousel-pager { position: absolute; top: 50%; margin-top: -20px; width: 20px; height: 20px; border-radius: 50%; background: #fcfcfd; text-align: center; line-height: 20px; font-size: 14px; color: $primary-color;
        &.pager-left { left: 0; margin-left: -10px; }
        &.pager-right { right: 0; margin-right: -10px; }
    }
    @media all and (max-width: 767px) {
        .carousel-pager {
            &.pager-left { margin-left: 0px; }
            &.pager-right { margin-right: 0px; }
        }
    }
}


/* ---------------------------

	MAIN-CONTENT

--------------------------- */

#main-content { display: block; float: left; width: 100%; background: #fff; padding: 50px 0; }


/* ---------------------------

	BOX

--------------------------- */

.box { display: block; float: left; width: 100%; margin-bottom: 20px;
    .box-label { display: block; float: left; padding: 14px 30px; border-top-left-radius: 4px; border-top-right-radius: 4px; font-size: 16px; font-family: $secondary-font; }
    .box-content { display: block; float: left; padding: 24px 30px; width: 100%; border-top-right-radius: 4px; border-bottom-left-radius: 4px; border-bottom-right-radius: 4px; }

    &.grey {
        .box-label { background: $grey-bg; }
        .box-content { background: $grey-bg; }
    }

    p { color: #8a9096; }
}

.user-box { display: block; float: left; width: 100%; margin-top: 20px; margin-bottom: 20px;
    .top { display: block; float: left; width: 100%; text-align: center; padding: 0 30px 30px 30px; background: #eff1f4; border-top-left-radius: 4px; border-top-right-radius: 4px;
        .user-image { width: 90px; height: 90px; border-radius: 4px; margin-top: -20px; }
        h2 { padding-top: 25px; font-size: 16px;
            .pink { font-size: 14px; }
        }
    }
    .content { display: block; float: left; width: 100%; padding: 30px; background: #f5f6f7; border-bottom-left-radius: 4px; border-bottom-right-radius: 4px;
        .user-links { display: block; float: left; width: 100%; list-style: none; margin: 0 0 0 0; padding: 0 0 0 0;
            li { display: block; float: left; width: 100%; margin: 0 0 0 0; padding: 0 0 0 0;
                i { color: $theme-color; font-size: 10px; }
                a { color: $primary-color; line-height: 22px;
                    &.pink { color: $theme-color; }
                    &:hover { text-decoration: underline; }
                }
            }
        }
    }
}


/* ---------------------------

	THUMB-LIST

--------------------------- */

.thumb-list { display: block; float: left; width: 100%; list-style: none; margin: 0 0 0 0; padding: 0 0 0 0;
    > li { display: block; float: left; width: 100%; padding: 6px 0;
        a { color: $primary-color; font-size: 14px;
            .media {
                .media-left { display: block;
                    img { display: block; float: left; width: 68px; height: 68px; border-radius: 3px; margin: 0 10px 0 0; }

                    @media all and (max-width: 1199px) {
                        img { width: 50px; height: 50px; }
                    }
                    @media all and (max-width: 991px) {
                        img { width: 68px; height: 68px; }
                    }
                }

                .media-body {
                    .recipe-name { display: block; }
                    .by { display: block; font-size: 13px; color: #8a9096; font-style: italic; }
                    .date { display: block; font-size: 13px; color: #8a9096; font-style: italic; }
                }
            }
        }

        &:hover {
            img { opacity: .8; }
        }
    }
}


/* ---------------------------

	POLL-FORM

--------------------------- */

.poll-form { display: block; float: left; width: 100%;
    button { margin-top: 10px; }
}


/* ---------------------------

	ADS-BLOCK

--------------------------- */

.lysing-block { display: block; float: left; width: 100%; margin-bottom: 20px;
    span.lysing-description { display:block; float:left; width: 100%; font-family: $secondary-font; font-size: 13px; color: #969ca8; padding-bottom: 10px; }
    ul.lysing { display: block; float: left; width: 100%; list-style: none; margin: 0 0 0 0; padding: 0 0 0 0;
        li { display: block; float: left; width: 100%; margin: 10px 0; padding: 0 0 0 0;
            &:first-child { margin-top: 0; }
            &:last-child { margin-bottom: 0; }
            img { width: 100%; height: auto; border-radius: 4px; }
        }
    }
}


/* ---------------------------

	RECIPES-LIST

--------------------------- */

.recipes-list { display: block; float: left; width: calc( 100% + 30px ); margin: 15px 0 15px -15px; list-style: none; padding: 0 0 0 0;
    .item { display: block; float: left; padding-top: 15px; padding-bottom: 15px;
        > a {
            .image { display: block; float: left; width: 100%; overflow: hidden; border-top-left-radius: 4px; border-top-right-radius: 4px; position: relative;
                img { width: 100%; height: auto; }
                .hover { display: block; float: left; width: 100%; height: 100%; position: absolute; background: rgba(255,67,107, .8); top: 0; left: 0; text-align: center; opacity: 0;
                    i { font-size: 30px; color: #fff; position: relative; top: 42%; }
                }
            }
            .desc { display: block; float: left; width: 100%; background: #f5f6f7; overflow: hidden; border-bottom-left-radius: 4px; border-bottom-right-radius: 4px; padding: 20px;
                h3 { font-size: 17px; color: $primary-color; margin: 0 0 4px 0; }
                .category { font-size: 15px; color: $theme-color; }
                .rating { display: block; float: right; margin-top: 4px;
                    i { display: block; float: left; font-size: 13px; padding: 0 1px; color: $theme-color; }
                }
            }

            &:hover {
                .image {
                    img { opacity: .8; }
                    .hover { opacity: 1; }
                }
            }
        }
    }
}

.more-recipes-bar-btn { display: block; float: left; width: 100%; text-align: right; padding: 15px 20px; background: #f5f6f7; color: $theme-color; border-radius: 4px; font-size: 14px;
    &:hover { color: $theme-color-hover; background: #dfdfdf; }
    i { font-size: 12px; }
}



/* ---------------------------

	RECOMMENDED

--------------------------- */

#recommended { display: block; float: left; width: 100%; background: #f5f6f7;
    h2.sectionTitle { margin-bottom: 0; }
}


/* ---------------------------

	FOOTER

--------------------------- */

#footer { display: block; float: left; width: 100%; background: #2a2b2c; color: #f9fafb;
    .footer-logo {
        img { max-width: 100%; height: auto; margin-top: 20px; }
    }
    h4 { font-size: 17px; }
    ul.footer-links { display: block; float: left; width: 100%; list-style: none; margin: 0 0 30px 0; padding: 0 0 0 0;
        li { padding: 3px 0;
            a { color: #f9fafb;
                &:hover { text-decoration: underline; }
            }
        }
    }

    @media all and (max-width: 991px) {
        .footer-logo { text-align: center;
            img { width: 200px; margin-top: 0; margin-bottom: 30px; }
        }
    }
}


/* ---------------------------

	LEGAL-FOOTER

--------------------------- */

#legal-footer { display: block; float: left; width: 100%; color: #abb0b4; font-size: 12px; padding: 28px 0;
    .social-footer { display: block; float: left; width: 100%; text-align: center; margin: 0 0 0 0; padding: 4px 0 0 0; list-style: none;
        li { display: inline-block; padding: 0 3px; margin: 0 0 0 0;
            a { font-size: 17px; color: #abb0b4;
                &:hover { color: #404040; }
            }
        }
    }
}


/* ---------------------------

	SUB-PAGE: SEARCH RESULTS

--------------------------- */

#search-results {

    .search-holder { display: block; float: left; width: 100%; margin-top: 15px; margin-bottom: 30px;
        #search { display: block; float: left;
            form { display: block; float: left; width: 100%;
                input.search { display: block; float: left; width: 100%; height: 55px; border-radius: 4px; background-color: #f5f6f7; border: none; padding-left: 20px; padding-right: 75px; border: 1px solid #eee; outline: none; }
                button { display: block; float: right; margin-top: -55px; height: 55px; width: 55px; background: $theme-color; border: none; border-top-right-radius: 4px; border-bottom-right-radius: 4px; color: #fff; position: relative; z-index: 110; }
            }
        }
    }

    .recipes-list { margin-top: 50px; }

}


/* ---------------------------

	PAGINATION

--------------------------- */

.pagination { display: block; float: left; width: 100%; background: #f5f6f7; border-radius: 4px; padding: 25px 15px; text-align: center;
    ul.pages { display: block; float: left; width: 100%; margin: 0 0 0 0; padding: 0 0 0 0; list-style: none;
        li { display: inline-block; padding: 0 1px; margin: 0 0 0 0;
            a { display: block; float: left; width: 32px; height: 34px; border-radius: 4px; border: 1px solid rgba(205,205,205,.8); line-height: 34px; text-align: center; color: #919191; box-shadow: 0 0 1px rgba(0,0,0,.05); background: #fff;
                &:hover { background: #ccc; color: #fff; }
            }
            &.active {
                a { background: $theme-color; color: #fff; }
                &:hover {
                    a {
                        background: $theme-color-hover; color: #fff;
                    }
                }
            }
            &.previous { padding-right: 15px; }
            &.next { padding-left: 15px; }
        }
    }
}


/* ---------------------------

	RECIPE DETAILS

--------------------------- */

#recipe-details {
    .user-recipe { margin-bottom: 15px; padding: 15px; margin-top: 20px; background: #f5f6f7; border-radius: 4px;
        .user-btns { display: block; float: right; list-style: none; margin: -5px 0 0 0; padding: 0 0 0 0; width: auto;
            li { display: block; float: left; margin-left: 5px;
                a { text-transform: none; }
            }
        }
    }
    .recipe-name-and-recipe-rate { margin-bottom: 20px;
        .recipe-name { float: left; }
        .recipe-rate { float: right; margin-top: -5px;
            .rating-box { display: inline-block; float: left; width: 100%; color: #6d7378;
                span { float: right; margin-right: 15px; }
                fieldset { margin-right: 15px; }
            }
        }
    }

    .chef-tab { display: block; float: left; width: 100%; position: relative;
        p { margin-bottom: 0; line-height: 16px; }
        .user-thumb { display: block; float: left; margin: 0 8px 8px 0; border-radius: 4px; }
        .uploaded-by { padding-top: 4px; font-family: $secondary-font; font-size: 15px; line-height: 20px; }
        .date { font-family: $secondary-font; font-size: 12px; }
        .category { font-family: $secondary-font; font-size: 12px; }
        .votes { font-family: $secondary-font; font-size: 12px;
            .text { float: left; margin-right: 2px; }
            .rating { font-size: 20px;
                i { display: block; float: left; font-size: 13px; padding: 0 1px; margin-top: 1px; color: $theme-color; }
            }
        }

        @media all and (max-width: 767px) {

        }

        // @media all and (max-width: 767px) {
        //     ul.print-share { position: relative; margin-top: 10px; }
        // }

    }

    ul.print-share { display: block; float: right; margin: 8px -2px 0 0; padding: 0 0 0 0; list-style: none;
        li { display: block; float: left; margin: 0 0 0 0; padding: 2px;
            a { font-size: 12px; }
        }
    }

    @media all and (max-width: 767px) {
        ul.print-share { margin-bottom: 15px; }
    }

    .ingredients { padding: 0 30px 0 0; margin-top: 30px;
        h2 { margin-bottom: 30px; }
        p { margin-bottom: 7px; }
    }

    .information { display: block; float: left; width: 100%; border-top: 1px dotted #d1d1d1; padding: 15px 0; margin: 15px 0;
        .table { margin-bottom: 0; }
        .table>tbody>tr>td { border-top: none; }
    }

    .method { padding: 0 0 0 30px; margin-top: 30px; border-left: 1px dotted #d1d1d1;
        h2 { margin-bottom: 30px; }
        p { line-height: 25px; font-weight: 300; }
    }

    @media all and (max-width: 767px) {
        .ingredients { padding: 0 0 30px 0; border-bottom: 1px dotted #d1d1d1; }
        .method { padding: 30px 0 0 0; border-left: none; }
    }
}

#recipe-slider-wrapper { display: none; float: left; width: 100%; margin-top: 20px; margin-bottom: 0px; position: relative;
    ul.recipe-slider { display: block; float: left; width: 100%; list-style: none; padding: 0 0 0 0; margin: 0 0 0 0;
        li { display: block; float: left; width: 100%; padding: 0 0 0 0; margin: 0 0 0 0;
            img { border-radius: 4px; }
        }
    }
    .recipe-pager { display: block; position: absolute; top: 35%;
        a { display: block; float: left; background: #f5f6f7; line-height: 104px; text-align: center; font-size: 16px; color: $primary-color; width: 33px; height: 104px; cursor: pointer; }
        &.recipe-slider-prev { left: 0;
            a { border-top-right-radius: 5px; border-bottom-right-radius: 5px; }
        }
        &.recipe-slider-next { right: 0;
            a { border-top-left-radius: 5px; border-bottom-left-radius: 5px; }
        }
        &:hover {
            a { background: $theme-color; color: #fff; }
        }
    }
    .recipe-slider-pager { display: block; float: left; margin-top: -80px; margin-left: 30px; position: relative; z-index: 800;
        a { display: block; float: left; padding-right: 10px;
            img { width: 50px; height: 50px; border-radius: 4px; }
            &.active {
                img { border: 2px solid #fff; }
            }
        }
    }

    @media all and (max-width: 767px) {
        .recipe-slider-pager { display: none; }
        .recipe-pager { display: none; }
    }

}


/* ---------------------------

	COMMENT

--------------------------- */

#write-comment { display: block; float: left; width: 100%; background: #f5f6f7; padding: 30px; border-radius: 4px;
    h2 { margin: 0 0 10px 0; color: $theme-color; }


    textarea { min-height: 120px; margin-bottom: 15px; }
    textarea { background: #fff; border: none !important; box-shadow: none !important; padding: 20px !important; max-width: 100%; }
}

#recipe-details {
    #write-comment { margin-top: 50px; }
}


/* ---------------------------

	RATING

--------------------------- */

.rating-box {
    fieldset, label { margin: 0; padding: 0; }

    /****** Style Star Rating Widget *****/

    .rating {
    border: none;
    float: left;
    }

    .rating > input { display: none; }
    .rating > label:before {
    margin: 2px;
    font-size: 22px;
    font-family: FontAwesome;
    display: inline-block;
    content: "\f005";
    }

    .rating > .half:before {
    content: "\f089";
    position: absolute;
    }

    .rating > label {
    color: #ddd;
    float: right;
    }

    /***** CSS Magic to Highlight Stars on Hover *****/

    .rating > input:checked ~ label, /* show gold star when clicked */
    .rating:not(:checked) > label:hover, /* hover current star */
    .rating:not(:checked) > label:hover ~ label { color: $theme-color;  } /* hover previous stars in list */

    .rating > input:checked + label:hover, /* hover current star when changing rating */
    .rating > input:checked ~ label:hover,
    .rating > label:hover ~ input:checked ~ label, /* lighten current selection */
    .rating > input:checked ~ label:hover ~ label { color: $theme-color;  }
}


/* ---------------------------

	COMMENTS

--------------------------- */

#comments { display: block; float: left; width: 100%; margin: 50px 0 0 0;
    h2 { color: $theme-color; }

    ul.comment-stream { width: 100%; list-style: none; margin: 15px 0 50px 0; padding: 0 0 0 0;
        li.comment { width: 100%; padding: 30px; border-radius: 4px; margin: 0 0 0 0; overflow: hidden;
            &:nth-child(odd) { background: #f5f6f7; }
            .media-left { padding-right: 20px;
                .media-object { border-radius: 4px; width: 92px; height: 92px; max-width: none; }
                @media all and (max-width: 767px) {
                    .media-object { width: 68px; height: 68px; }
                }
            }
            @media all and (max-width: 767px) {
                .media-left { padding-right: 10px; }
            }
            .media-body {
                .name { display: block; float: left; width: 100%; font-size: 16px; color: $theme-color; font-weight: 700; }
                .date { display: block; float: left; width: 100%; font-size: 13px; color: #8f969d; }
                p { display: block; float: left; width: 100%; margin-top: 8px; }
            }
        }
    }
}


/* ---------------------------

	UPLOAD

--------------------------- */

#upload-recipe { display: block; float: left; margin-bottom: 20px;

    h1 { margin-top: 0px; }

    .info { display: block; float: left; width: 100%; padding: 30px; background: $theme-color; color: #fff; border-radius: 4px; margin: 15px 0 40px 0; font-family: $secondary-font;
        p { color: #fff; line-height: 25px;
            i { font-size: 12px; }
            &:last-child { margin-bottom: 0; }
        }
        b { font-weight: 700; }
    }

    h2 { margin-bottom: 15px; margin-top: 20px; }

    form {
        .more-photos-upload { display: block; float: left; padding-top: 40px; }
        .upload-button { margin-top: 50px; }
    }

    .uploaded-image { display: block; float: left; width: 68px; height: auto; border-radius: 4px; border: 1px solid #ccc; margin-top: 0px; margin-left: 40px; }
    .delete-uploaded-image { display: block; float: left; font-size: 24px; color: #b7b7b7; margin-top: 40px; margin-left: 24px;
        &:hover { color: $theme-color-hover; }
    }
}

/* ---------------------------

    FAVORITES AND MY RECIPES

---------------------------- */
.info { display: block; float: left; width: 100%; padding: 30px; background: $theme-color; color: #fff; border-radius: 4px; font-family: $secondary-font;
    p { color: #fff; line-height: 25px;
        i { font-size: 12px; }
        &:last-child { margin-bottom: 0; }
    }
    b { font-weight: 700; }
}

/* ---------------------------

	MAP

--------------------------- */

#map { display: block; float: left; width: 100%;
    iframe { width: 100%; height: 500px; }
    @media all and (max-width: 767px) {
        iframe { height: 250px; }
    }
}


/* ---------------------------

	CONTACT

--------------------------- */

#contact-us {
    form#contact-form { margin: 30px 0;
        .send-message { margin-top: 30px; }
    }
}


/* ---------------------------

	TESTIMONIALS

--------------------------- */

#testimonials { display: block; float: left; width: 100%;

    #testimonials-slider-wrapper { display: none; margin-top: 50px; position: relative;
        ul.testimonials-slider { display: block; float: left; width: 100%; list-style: none; margin: 0 0 0 0; padding: 0 0 0 0;
            li { margin: 0 0 0 0; padding: 0 0 0 0; text-align: center;
                img { border-radius: 4px; }
                h3 { font-size: 24px; font-family: $primary-font; margin-bottom: 5px; }
                p { font-size: 16px; font-weight: 300;
                    b { font-size: 18px; font-weight: 400; }
                }
             }
        }
        .testimonials-pager { display: block; float: left; position: absolute; top: 0; margin-top: 120px; font-size: 20px;
            &.testimonials-slider-prev { left: 0; margin-left: -100px; }
            &.testimonials-slider-next { right: 0; margin-right: -100px; }
        }

        @media all and (max-width: 1169px) {
            .testimonials-pager { margin-top: 95px; }
        }
        @media all and (max-width: 969px) {
            .testimonials-pager {
                &.testimonials-slider-prev { margin-left: -20px; }
                &.testimonials-slider-next { margin-right: -20px; }
            }
        }
        @media all and (max-width: 800px) {
            .testimonials-pager { display:none; }
        }

        .testimonials-slider-pager { display: block; float: left; width: 100%; text-align: center; margin-top: 30px;
            a { display: inline-block; width: 10px; height: 10px; border-radius: 50%; background: #d9e0e2; margin: 0 1px;
                &.active { background: $theme-color; }
            }
        }
    }

}
